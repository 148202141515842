import {ROLES} from "../../Components/Authorize/roleConstants";
import {findSiteAbbreviationForProperties} from "../../SiteConfig/siteUtilities";
import {convertToNumber} from "../../Utilities/commonUtilities";
import {dynamicSort} from "../../Utilities/Sort/";
import {createArrayFromObject} from "../../Utilities/Types/arrayUtilities";
import {MAX_DATE_RANGE} from "../../Utilities/Types/dateUtilities";
import {createListItem} from "../../Utilities/Types/listUtilities";
import {courseTypes} from "../Course/courseConstants";
import {BulkCertificatesDownloadPrintView} from "./PrintReports/BulkCertificatesDownloadPrintView";
import {reportTypeObject} from "./reportsFactory";
import SiteSummaryPrintView from "./PrintReports/SiteSummaryPrintView";

const aph_reports = {
    CourseEventData: reportTypeObject("Enrolled Event Data", "Generates an Excel report of users who have enrolled in events. The report includes enrollment/completion dates.", true, [], [courseTypes.EventCourse]),
    ProfessionalCommunityData: reportTypeObject("Enrolled Professional Community Data", "Generates an Excel report of users who have enrolled in professional communities. The report includes demographic information, and enrollment/last accessed dates.", true, [], [courseTypes.ProfessionalCommunityCourse]),
    NonCompleterSurvey: reportTypeObject("Survey Responses for Non-Completers", "Generates an Excel report of responses by course, for all participants who answered the “Non-Completers Survey” email questions.", true, [], []),
    ParticipantsByLocation: reportTypeObject("Participants By Location", "Generates an Excel report of enrollments and completers by location.", false, [], []),
    ReadingMediumByLocation: reportTypeObject("Reading Medium By Location", "Generates an Excel report a summary of type of reading medium used by location.", false, [], []),
    AssessmentData: reportTypeObject("Assessment Data", "Generates an Excel report of users’ assessment scores.", true, [], [courseTypes.RegularCourse], true, true),
    CykData: reportTypeObject("CYK Data", "Generates an Excel report of users’ CYK scores.  Date range based upon enrollment date.", true, [], [courseTypes.RegularCourse], false, true),
    AllCykData: reportTypeObject("All CYK Data", "Generates an Excel report of every attempt by a users' CYK scores. Date range based upon enrollment date.",true, [], [courseTypes.RegularCourse], false, true),
    AllAssessmentData: reportTypeObject("All Assessment Data", "Generates an Excel report of every attempt by a users’ assessment scores.", true, [], [courseTypes.RegularCourse], false, true),
    DownloadFollowUpActivities: reportTypeObject("Download Follow-Up Activities", "This is NOT a report. It allows you to download a zip file of the follow-up activity forms submitted per course. The type of files is determined by the course selected (e.g., signed Follow-up Activity Verification Form, Intent to Seek ASHA CEUs, or Intent to Seek OT/PT CEUs). ", true, [], [courseTypes.RegularCourse], false, true),
    SatisfactionSurvey: reportTypeObject("Satisfaction Survey Data", "Generates an Excel report a summary of the ratings from the end of course satisfaction survey questions. The data is not by individual users."),
    FollowUpHistoryData: reportTypeObject("Follow-Up Activity History Data", "Generates an Excel report of all Follow-Up Activity.", true),
    Enrollments: reportTypeObject("Enrolled User Data", "Generates an Excel report of users who have enrolled in courses. The report includes demographic information, assessment scores, and enrollment/completion dates.", true),
    SiteSummary: reportTypeObject("Site Summary", "Opens in a new window, a summary of the number of enrollments and completers per course with a total. It also includes the total number of enrollments and completers by location.", false, [], [], false, false, SiteSummaryPrintView)
};

const fgbri_reports = {
    ParticipantsByLocation: reportTypeObject("Participants By Location", "Generates an Excel report of enrollments and completers by location.", false, [], []),
    AssessmentData: reportTypeObject("Assessment Data", "Generates an Excel report of users’ assessment scores.", true, [], [courseTypes.RegularCourse], true, true),
    AllAssessmentData: reportTypeObject("All Assessment Data", "Generates an Excel report of every attempt by a users’ assessment scores.", true, [], [courseTypes.RegularCourse], false, true),
    SatisfactionSurvey: reportTypeObject("Satisfaction Survey Data", "Generates an Excel report a summary of the ratings from the end of course satisfaction survey questions. The data is not by individual users."),
    EnrolledUserProgressData: reportTypeObject("Enrolled User Progress Data", "Generates an Excel report of users who have enrolled in courses. The report includes demographic information, assessment scores, module completion dates, and enrollment/completion dates.", true),
    Enrollments: reportTypeObject("Enrolled User Data", "Generates an Excel report of users who have enrolled in courses. The report includes demographic information, assessment scores, and enrollment/completion dates.", true)
};

const earlyaccess_reports = {
    ParticipantsByLocation: reportTypeObject("Participants By Location", "Generates an Excel report of enrollments and completers by location.", false, [], []),
    AssessmentData: reportTypeObject("Assessment Data", "Generates an Excel report of users’ assessment scores.", true, [], [courseTypes.RegularCourse], true, true),
    SatisfactionSurvey: reportTypeObject("Satisfaction Survey Data", "Generates an Excel report a summary of the ratings from the end of course satisfaction survey questions. The data is not by individual users."),
    Enrollments: reportTypeObject("Enrolled User Data", "Generates an Excel report of users who have enrolled in courses. The report includes demographic information, assessment scores, and enrollment/completion dates.", true)
};

const fcrr_reports = {
    ParticipantsByLocation: reportTypeObject("Participants By Location", "Generates an Excel report of enrollments and completers by location.",  false,[], []),
    AssessmentData: reportTypeObject("Assessment Data", "Generates an Excel report of users’ assessment scores.",true, [], [courseTypes.RegularCourse], true, true),
    SatisfactionSurvey: reportTypeObject("Satisfaction Survey Data", "Generates an Excel report a summary of the ratings from the end of course satisfaction survey questions. The data is not by individual users."),
    Enrollments: reportTypeObject("Enrolled User Data", "Generates an Excel report of users who have enrolled in courses. The report includes demographic information, assessment scores, and enrollment/completion dates.", true)
};

const ficw_reports = {
    ParticipantsByLocation: reportTypeObject("Participants By Location", "Generates an Excel report of enrollments and completers by location.", false, [], []),
    AssessmentData: reportTypeObject("Assessment Data", "Generates an Excel report of users’ assessment scores.",true, [], [courseTypes.RegularCourse], true, true),
    ExternalCourseCykData: reportTypeObject("CYK Data", "Generates an Excel report of users’ CYK scores.", true, [], [courseTypes.RegularCourse], false, true),
    SatisfactionSurvey: reportTypeObject("Satisfaction Survey Data", "Generates an Excel report a summary of the ratings from the end of course satisfaction survey questions. The data is not by individual users."),
    Enrollments: reportTypeObject("Enrolled User Data", "Generates an Excel report of users who have enrolled in courses. The report includes demographic information, assessment scores, and enrollment/completion dates.", true)
};

const mapr_reports = {
    ExternalCourseCykDataNoDisplayIsCorrect: reportTypeObject("Assessment Data", "Generates an Excel report of users’ CYK scores.", false, [], [courseTypes.RegularCourse], false, true),
    Enrollments: reportTypeObject("Enrolled User Data", "Generates an Excel report of users who have enrolled in courses. The report includes demographic information, assessment scores, and enrollment/completion dates.", true),
    ModuleProgressData: reportTypeObject("Module Progress Data", "Generates an Excel report of users’ progress through the modules.", true, [], [courseTypes.RegularCourse], false, true)
};

const doh_reports = {
    ParticipantsByLocation: reportTypeObject("Participants By Location", "Generates an Excel report of enrollments and completers by location.", false, [], []),
    AllCykData: reportTypeObject("All CYK Data", "Generates an Excel report of every attempt by a users' CYK scores. Date range based upon enrollment date.",true, [], [courseTypes.RegularCourse], false, true),
    CykData: reportTypeObject("CYK Data", "Generates an Excel report of users’ CYK scores.  Date range based upon enrollment date.",true, [], [courseTypes.RegularCourse], false, true),
    CourseProgression: reportTypeObject("Course Progression", "Generates an Excel report of users and their progression through a course.  Date range based upon enrollment date.",true, [], [courseTypes.RegularCourse], false, true),
    SatisfactionSurvey: reportTypeObject("Satisfaction Survey Data", "Generates an Excel report a summary of the ratings from the end of course satisfaction survey questions. The data is not by individual users."),
    Enrollments: reportTypeObject("Enrolled User Data", "Generates an Excel report of users who have enrolled in courses. The report includes demographic information, assessment scores, and enrollment/completion dates.", true)
};

const doeoss_reports = {
    BulkCertificatesDownload: reportTypeObject("Bulk Certificates Download", "Opens in a new window and generates certificates (to download as PDFs and print) based upon completion date. The start and end dates filter the trainings that are available to select. Selecting a district or course/training is required. The district filter only includes participants from that district. The course/training filter will display all participants from that training.", false, [ROLES.SystemAdmin, ROLES.SiteAdmin, ROLES.TechSupport], [courseTypes.RegularCourse, courseTypes.Training], false, false, BulkCertificatesDownloadPrintView, true, true, MAX_DATE_RANGE.oneYear),
    BulkCertificatesDownloadDistricts: reportTypeObject("Bulk Certificates Download", "Opens in a new window and generates certificates (to download as PDFs and print) based upon completion date. The start and end dates filter the trainings that are available to select. The course/training filter displays all participants from that training from your district.", false, [ROLES.DistrictDataViewer], [courseTypes.RegularCourse, courseTypes.Training], false, false, BulkCertificatesDownloadPrintView, true, false, MAX_DATE_RANGE.oneYear),
    BulkCertificatesDownloadTrainings: reportTypeObject("Bulk Certificates Download", "Opens in a new window and generates certificates (to download as PDFs and print) based upon completion date. The start and end dates filter the trainings that are available to select. The district filter only includes participants from that district.", false,  [ROLES.Trainer], [courseTypes.Training], false, true, BulkCertificatesDownloadPrintView, true),
    ParticipantsByDistrict: reportTypeObject("Participants By District", "Generates an Excel report of enrollments and completers by district.", false, [ROLES.SystemAdmin, ROLES.SiteAdmin, ROLES.TechSupport, ROLES.DistrictDataViewer], []),
    UserEnrollments: reportTypeObject("Enrolled User Data", "Generates an Excel report of all non-completer users who have enrolled in courses.", true, [ROLES.SystemAdmin, ROLES.SiteAdmin, ROLES.TechSupport, ROLES.DistrictDataViewer]),
    CompleterData: reportTypeObject("Completer Data", "Generates an Excel report of all users who have enrolled and completed in courses.", true, [ROLES.SystemAdmin, ROLES.SiteAdmin, ROLES.TechSupport, ROLES.DistrictDataViewer]),
    AllUserData: reportTypeObject("All User Data", "Generates an Excel report of all users who have enrolled in courses, including completers and non-completers.", true, [ROLES.SystemAdmin, ROLES.SiteAdmin, ROLES.TechSupport, ROLES.DistrictDataViewer]),
};

const beess_reports = {
    AssessmentData: reportTypeObject("Assessment Data", "Generates an Excel report of users’ assessment scores.", true, [], [courseTypes.RegularCourse], true, true),
    DownloadFollowUpActivities: reportTypeObject("Zip File of Follow-Up Activity Forms", "This is NOT a report. It allows you to download a zip file of the follow-up activity forms submitted per course. The type of files is determined by the course selected (e.g., signed Follow-up Activity Verification Form, Intent to Seek ASHA CEUs, or Intent to Seek OT/PT CEUs). ", true, [], [courseTypes.RegularCourse], false, true),
    CompletersReport: reportTypeObject("Completer Data", "Generates an Excel report of users who have completed courses.", true),
    Enrollments: reportTypeObject("Enrolled User Data", "Generates an Excel report of users who have enrolled in courses. The report includes demographic information, assessment scores, and enrollment/completion dates.", true),
    CourseSummary: reportTypeObject("BEESS Quarterly Report", "Generates an Excel report of the number of users and completers for each Independent Study course.", false,  [ROLES.SystemAdmin], []),
    NonCompleterSurvey: reportTypeObject("Survey Responses for Non-Completers", "Generates an Excel report of responses by course, for all participants who answered the “Non-Completers Survey” email questions.", true, [ROLES.SystemAdmin], []),
    CompleterSurvey: reportTypeObject("Survey Responses for Completers", "Generates an Excel report of responses by course, for all participants who answered the “Completers Survey” email questions.", true, [ROLES.SystemAdmin], []),
    SatisfactionSurvey: reportTypeObject("Satisfaction Survey Data", "Generates an Excel report a summary of the ratings from the end of course satisfaction survey questions. The data is not by individual users.", false, [ROLES.SystemAdmin]),
};

const allReports = {
    aph: {...aph_reports},
    earlyaccess: {...earlyaccess_reports},
    fgrbi: {...fgbri_reports},
    fcrr: {...fcrr_reports},
    mapr: {...mapr_reports},
    ficw: {...ficw_reports},
    doeoss: {...doeoss_reports},
    doh: {...doh_reports},
    beess: {...beess_reports}
};

const usesQuartersFilter = ['beess'];

export const ShowDeleteFollowUpFilesReportType = "DownloadFollowUpActivities";

export const getReportTypeList = (siteGroupId, allSiteDetails, selectedRole) => {
    const siteAbbreviation = findSiteAbbreviationForProperties(siteGroupId, allSiteDetails);

    const allSiteReports = createArrayFromObject(allReports[siteAbbreviation]);

    const userRole = convertToNumber(selectedRole);
    let result = [];
    for(let report of allSiteReports) {
        if((report.roles.length > 0 && report.roles.indexOf(userRole) > -1) || report.roles.length === 0) {
            result.push({...createListItem(report.propertyId, report.reportTitle), ...{reportDetails: report}});
        }
    }

    return result.sort(dynamicSort("text"));
};

export const usesCreditOptions = (reportType) => {
    return reportType === ShowDeleteFollowUpFilesReportType;
};

export const createQuartersList = () => {
    let quarters = [];
    quarters.push(createListItem('0', 'All Quarters'));
    quarters.push(createListItem('1', 'Quarter 1'));
    quarters.push(createListItem('2', 'Quarter 2'));
    quarters.push(createListItem('3', 'Quarter 3'));
    quarters.push(createListItem('4', 'Quarter 4'));

    return quarters;
};

export const quarterOptions = {
    startDate: "startDate",
    endDate: "endDate"
};

export const displaysQuarterList = (siteGroupId, allSiteDetails) => {
    const siteAbbreviation = findSiteAbbreviationForProperties(siteGroupId, allSiteDetails);
    return !!usesQuartersFilter.find(f => f === siteAbbreviation);
};